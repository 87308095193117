import React, { useEffect, useState } from 'react'
import { LoginPage,LoginWrap,LoginDiv,AppName, SchoolLogo, AppDesc, HDSS_Form, FormInput, HDSS_Label, Button } from '../data/Styles'

import { useNavigate } from 'react-router-dom'
import { AES, enc } from 'crypto-js';
import logo from "../data/HydotLogo.png"
import {Show} from "../data/Alerts"
import {apiServer} from "../data/Endpoint"
import { colors } from '../data/Colors';
import Selector from '../data/Selector';
import { continentList, countryList } from '../data/champion';
import SelectorSmall from '../data/SelectorSmall';
const Login = () => {

  const navigate = useNavigate();
  const [userId, setuserId] = useState("");
  const [userPassword, setuserPassword] = useState("")
  const [Teach, setTeach] = useState("")
  const [admin, setadmin] = useState("")
  

  
 
const [a,sa] = useState("")
const [b,sb] = useState("")


const SubMit = async () =>{

  try{
    const formData = new FormData();
   
    formData.append("Email",a)
      formData.append("Password",b)
    
    
    Show.showLoading("Processing Data")

    const response = await fetch(apiServer+"LogIn", {
      method: "POST",
      body: formData,
    });

    

    const data = await response.json();
    if(response.ok){
      Show.hideLoading();

        Show.Success("Enter Your Verification Code");
        sessionStorage.setItem("UserId",data.message);
         
        navigate("/verify")
    }
    else{
      Show.Attention(data.message);
    }


  }
  
  catch(e){
    Show.Attention("An error has occurred")
  }




}


const editing = { allowDeleting: true, };

const [continent, setContinent] = useState("")
const [country, setCountry] = useState("")
const [picture, setPicture] = useState("")
const [name, setName] = useState("")
const [location, setLocation] = useState("")
const [phone, setPhone] = useState("")
const [email, setEmail] = useState("")
const [AdminUserId,setAdminUserId] = useState("")
const [AdminList, setAdminList] = useState([])


const handleCreateAdmin = async () => {

 

    Show.showLoading("Processing Data");
      try {
    
    const formData = new FormData()
    formData.append("Email", email)
    formData.append("Location", location)
    formData.append("Name", name)
    formData.append("Picture", picture)
    
    formData.append("Continent", continent)
    formData.append("Country", country)
    formData.append("Phone", phone)
    
  
    
        const response = await fetch(apiServer+"CreatePartner", {
          method: "POST",

          body:formData
        });
    
        const data = await response.json();
     
    
        if (response.ok) {
          
          Show.hideLoading();
    
          Show.Success(data.message);
          navigate("/")
          // window.location.reload()
          
        } else {
          Show.Attention(data.message);
        }
      } catch (error) {
    
        Show.Attention("An error has occured");
       
      }
    
    }
    














  return (
   <LoginPage>

<LoginWrap>

<LoginDiv background={colors.primary} flex={0.55} hide={true}>
      
      <img
        src={logo}
        style={{
          width: "100%",
          height: "100%",
          borderRadius: 10,
          top:0

        }}
        alt="Introduction"
      />

    </LoginDiv>



        <LoginDiv background={"white"} flex={0.45} hide={false}>
      
        <SchoolLogo
            src={logo}
            alt="icon"
            style={{ width: 65, height: 65 }}
          />
          <AppDesc style={{ color: "black", marginTop: 20, marginBottom: 50 }}>
            Sign up 
          </AppDesc>

          <HDSS_Form
          
          style={{ fontSize: 12 }}
          >

<SelectorSmall placeholder="Select Continent" dataList={continentList} dataKey="name" dataValue="name" setMethod={(method) => setContinent(method)} />
<SelectorSmall placeholder="Select Country" dataList={countryList} dataKey="country" dataValue="country" setMethod={(method) => setCountry(method)} /> <br/>



            <HDSS_Label>Picture *</HDSS_Label>
           
                <FormInput
                 type="file"
                 required
                 placeholder=""
                 accept=".jpg, .png, .jpeg, .ico"
                 onChange={(e) => setPicture(e.target.files[0])}
                 
                />

          <HDSS_Label>Name *</HDSS_Label>
           
           <FormInput
             type="text"
             required
             placeholder=""
             onChange={(e) => setName(e.target.value)}
           />

          <HDSS_Label>Location *</HDSS_Label>
           
           <FormInput
             type="text"
             required
             placeholder=""
             onChange={(e) => setLocation(e.target.value)}
           />

        <HDSS_Label>Phone *</HDSS_Label>
           
           <FormInput
             type="text"
             required
             placeholder=""
             onChange={(e) => setPhone(e.target.value)}
           />

        <HDSS_Label>Email *</HDSS_Label>
           
           <FormInput
             type="email"
             required
             placeholder=""
             onChange={(e) => setEmail(e.target.value)}
           />













          <Button
          color="white"
         
          style={{ marginTop: 25, width: "100%", }}
          onClick={()=>{handleCreateAdmin()}}
          >

          Join us
          </Button>
          

          </HDSS_Form>




          <p style={{ textAlign: "center", fontSize: 10, color: "gray", marginTop:"10vh"}}>
            Hydot Tech Admin Portal © {new Date().getFullYear()} All rights
            reserved.
          </p>
          <p style={{ textAlign: "center", fontSize: 10, color: "gray" }}>
            v.1.1.1
          </p>


        </LoginDiv>

      

</LoginWrap>





   </LoginPage>
  )
}

export default Login