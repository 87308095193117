import React, { useEffect, useRef, useState } from 'react';
import { GlobalWorkerOptions, getDocument } from 'pdfjs-dist';
import 'pdfjs-dist/web/pdf_viewer.css';

// Set workerSrc to use a CDN
GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/3.8.0/pdf.worker.min.js`;

// Helper function to detect mobile devices
const isMobile = () => /Mobi|Android/i.test(navigator.userAgent);

const PDFViewer = ({ pdfUrl }) => {
  const canvasRef = useRef(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [numPages, setNumPages] = useState(null);
  const [pdfDocument, setPdfDocument] = useState(null);

  const renderPage = (pdf, pageNum) => {
    pdf.getPage(pageNum).then((page) => {
      const scale = 1.5;
      const viewport = page.getViewport({ scale });

      const canvas = canvasRef.current;
      const context = canvas.getContext('2d');

      canvas.height = viewport.height;
      canvas.width = viewport.width;

      const renderContext = {
        canvasContext: context,
        viewport: viewport,
      };
      page.render(renderContext);
    });
  };

  useEffect(() => {
    const loadingTask = getDocument(pdfUrl);
    loadingTask.promise.then((pdf) => {
      setPdfDocument(pdf);
      setNumPages(pdf.numPages);
      renderPage(pdf, pageNumber);
    }).catch(err => {
      console.error('Error loading PDF:', err);
    });
  }, [pdfUrl, pageNumber]);

  const handlePageChange = (newPageNumber) => {
    if (newPageNumber >= 1 && newPageNumber <= numPages) {
      setPageNumber(newPageNumber);
      if (pdfDocument) {
        renderPage(pdfDocument, newPageNumber);
      }
    }
  };

  const handleImageClick = () => {
    const link = document.createElement('a');
    link.href = pdfUrl;
    link.download = 'document.pdf'; // You can customize the filename
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <div>
      {isMobile() ? (
        <div>
          <img
            src="/path/to/your/placeholder-image.png" // Provide a path to your placeholder image
            alt="PDF Placeholder"
            style={{ width: '100%', cursor: 'pointer' }}
            onClick={handleImageClick}
          />
          <p>Click the image to download the PDF</p>
        </div>
      ) : (
        <div>
          <canvas ref={canvasRef}></canvas>
          <div>
            <button
              disabled={pageNumber <= 1}
              onClick={() => handlePageChange(pageNumber - 1)}
            >
              Previous Page
            </button>
            <span>
              Page {pageNumber} of {numPages}
            </span>
            <button
              disabled={pageNumber >= numPages}
              onClick={() => handlePageChange(pageNumber + 1)}
            >
              Next Page
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default PDFViewer;
