import React, { useEffect } from 'react';
import { BrowserRouter, Routes, Route, useNavigate, Navigate } from 'react-router-dom';
import { FiSettings } from 'react-icons/fi';
import { TooltipComponent } from '@syncfusion/ej2-react-popups';

import { Navbar, Footer, Sidebar, ThemeSettings } from './components';
import { DashBoard, Calendar,  Editor } from './pages';
import './App.css';

import { useStateContext } from './contexts/ContextProvider';

import Customers from './pages/UserMgmt/Customers';

import InstantReply from './pages/Messages/InstantReply';
import FollowUp from './pages/Messages/FollowUp';
import MyProfile from './pages/Admin/MyProfile';

import MainChat from './pages/MainChat';
import Sales from './pages/UserMgmt/Sales';
import ResourcesList from './pages/Admin/ResourceList';

const MainPage = () => {
  const { setCurrentColor, setCurrentMode, currentMode, activeMenu, currentColor, themeSettings, setThemeSettings } = useStateContext();

  useEffect(() => {
    const currentThemeColor = localStorage.getItem('colorMode');
    const currentThemeMode = localStorage.getItem('themeMode');
    if (currentThemeColor && currentThemeMode) {
      setCurrentColor(currentThemeColor);
      setCurrentMode(currentThemeMode);
    }
  }, []);

  useEffect(() => {
    const observer = new ResizeObserver(() => {
      try {
        // Intentional empty block
      } catch (err) {
        if (err.message === 'ResizeObserver loop completed with undelivered notifications.') {
          console.warn('ResizeObserver loop error detected.');
        } else {
          throw err;
        }
      }
    });

    observer.observe(document.body); // Assuming observing the body for changes

    return () => observer.disconnect();
  }, []);

  const navigate = useNavigate()

  useEffect(() => {
    let activityTimer;
  
    const clearSessionAndNavigate = () => {
      sessionStorage.clear();
    navigate("/")

    };
  
    const resetTimer = () => {
      clearTimeout(activityTimer);
  
      activityTimer = setTimeout(clearSessionAndNavigate, 300000); 
    };
  
    const resetTimerOnActivity = () => {
      if (activityTimer) {
        clearTimeout(activityTimer);
      }
      resetTimer();
    };
  
    // Initial setup and event listeners
    resetTimer();
    window.addEventListener('mousemove', resetTimerOnActivity);
    window.addEventListener('keypress', resetTimerOnActivity);
  
    // Clean up event listeners on component unmount
    return () => {
      window.removeEventListener('mousemove', resetTimerOnActivity);
      window.removeEventListener('keypress', resetTimerOnActivity);
      clearTimeout(activityTimer);
    };
  }, [navigate]);


  return (
    <div className={currentMode === 'Dark' ? 'dark' : ''}>
      
      <div>
        <div className="flex relative dark:bg-main-dark-bg">
          <div className="fixed right-4 bottom-4" style={{ zIndex: '1000' }}>
            <TooltipComponent
              content="Settings"
              position="Top"
            >
              <button
                type="button"
                onClick={() => setThemeSettings(true)}
                style={{ background: currentColor, borderRadius: '50%' }}
                className="text-3xl text-white p-3 hover:drop-shadow-xl hover:bg-light-gray"
              >
                <FiSettings />
              </button>

            </TooltipComponent>
          </div>
          {activeMenu ? (
            <div className="w-72 fixed sidebar dark:bg-secondary-dark-bg bg-white ">
              <Sidebar />
            </div>
          ) : (
            <div className="w-0 dark:bg-secondary-dark-bg">
              <Sidebar />
            </div>
          )}
          <div
            className={
              activeMenu
                ? 'dark:bg-main-dark-bg  bg-main-bg min-h-screen md:ml-72 w-full  '
                : 'bg-main-bg dark:bg-main-dark-bg  w-full min-h-screen flex-2 '
            }
          >
            <div className="fixed md:static bg-main-bg dark:bg-main-dark-bg navbar w-full ">
              <Navbar />
            </div>
            <div>
              {themeSettings && (<ThemeSettings />)}

              <Routes>
                {/* dashboard  */}
                <Route path="DashBoard" element={(<DashBoard/>)} />

                {/* User Management  */}
               
                <Route path="customers" element={<Customers />} />
             

                {/* MainPages  */}
              
                <Route path="calendar" element={<Calendar />} />
                <Route path="assignProduct" element={<Sales />} />
                <Route path="instantReply/:Id" element={<InstantReply />} />
                <Route path="allMessages" element={<FollowUp />} />
                <Route path="myProfile" element={<MyProfile />} />
                <Route path="mainChat" element={<MainChat />} />
                <Route path="resourceList" element={<ResourcesList />} />




                <Route path="*" element={<Navigate to="/" />} />
                

               
               
               

              </Routes>
            </div>
            <Footer />
          </div>
        </div>
      </div>

    </div>
  );
};

export default MainPage;
